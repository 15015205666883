<!--
 * @Descripttion: 
 * @version: 
 * @Author: xyh
 * @Date: 2021-04-19 16:13:58
 * @LastEditTime: 2021-04-23 18:09:38
-->
<template>
  <div class="task_detail">
    <div class="header">
      <span class="ret" @click="toTaskManage">任务管理/</span> <span>任务详情</span>
    </div>
    <div class="header-one">
      <div class="rask-title">
        <div class="rt-left">
          <div class="rt-left-title">{{taskDetailInfo.title || ''}}</div>
          <div class="rt-left-time">{{taskDetailInfo.createTime || ''}}发布</div>
        </div>
        <div class="rt-right">
          <a-button @click="closeTask"  v-if="taskDetailInfo.status <= '20'">关闭任务</a-button>
          <!-- <a-button
            
            @click="cancelRask"
            style="margin-left: 12px"
            >取消任务</a-button
          > -->
        </div>
      </div>
      <div class="rask-content">
        <a-row :gutter="24">
          <a-col :span="8" class="rc-mgb">任务类别：{{taskTypeName || ''}}</a-col>

          <a-col :span="8" class="rc-mgb"
            >任务起止日期：{{taskDetailInfo.workBeginDate || ''}}至{{taskDetailInfo.workEndDate || ''}}</a-col
          >
          <a-col :span="8" class="rc-mgb">预计人数：{{taskDetailInfo.needNum || ''}}人</a-col>
          <a-col :span="8" class="rc-mgb">
            任务状态：
            <span v-if="taskDetailInfo.status === '10'">待审核</span>
            <span v-if="taskDetailInfo.status === '11'">审核失败</span>
            <span v-if="taskDetailInfo.status === '13'">报名中</span>
            <span v-else-if="taskDetailInfo.status === '14'">报名截止</span>
            <span v-else-if="taskDetailInfo.status === '20'">进行中</span>
            <span v-else-if="taskDetailInfo.status === '30'">已完成</span>
            <span v-else-if="taskDetailInfo.status === '50'">已取消</span>
          </a-col>
          <a-col :span="8" class="rc-mgb"
            >预算金额：<span style="color: #1890ff">{{taskDetailInfo.budgetAmount || ''}}元</span></a-col
          >

          <a-col :span="8" class="rc-mgb">任务描述：{{taskDetailInfo.content || ''}}</a-col>
        </a-row>
      </div>
    </div>
    <!-- 发起付款 -->
    <div class="go_pay">
      <a-button type="primary" @click="visiblePl = true" v-if="taskDetailInfo.status <= '20'">发起付款</a-button>
    </div>
    
    <!-- <div class="go_pay">
      <a-button type="primary" @click="goSettlementDetail">发起付款</a-button>
    </div> -->

    <!-- 列表 -->
    <div class="table">
      <s-table
        style="border-top: 1px solid #f0f0f0; margin-top: 16px"
        ref="yfkTable"
        :columns="columnsYfkjsd"
        :data="dataYfkjsd"
        :rowKey="(record) => record.id"
      >
      <span slot="cycle" slot-scope="text, record">
              <span>{{record.payPeriodStartTime}} 至 {{record.payPeriodEndTime}}</span>
            </span>
            <span slot="action" slot-scope="text, record">
              <a @click="payDetail(record)" style="padding-right:10px">付款详情</a>
              
              <a-button style="padding:0" type="link" @click="submitPay(record)" :disabled="(record.payStatus==='40'&&record.payAmount!==0)?false:true">提交审核付款</a-button>
            </span>
      </s-table>
    </div>

    <!-- 批量发起付款 -->
    <div>
      <!-- 批量邀请 -->
      <a-modal
        title="发起付款"
        width="850px"
        :visible="visiblePl"
        :footer="null"
        @cancel="handleCancelPl"
      >
        <div class="pl_time">
            <div>
                用工周期：<a-range-picker @change="rwTime" v-decorator="['rwsj', {rules: [{ type: 'array', required: true, message: '请选择用工周期!' }]}]" />
            </div>
            <div style="position: relative; align-items: center;width:20%">
          <a-upload
            name="file"
            :multiple="false"
            accept=".xlsx"
            :action="url"
            :headers="headers"
            :fileList="uploadInfo"
            :data="importData"
            @change="handleChange"
          >
            <a-button> 导入数据 </a-button>
          </a-upload>
          <a
            href="https://tax-trade.oss-cn-chengdu.aliyuncs.com/template/batch_employment_template.xlsx"
            target="blink"
            class="xzmb"
            >下载模板</a
          >
        </div>
        </div>
        <div class="pl-table" >
          <s-table
            ref="raskTable"
            :columns="columnsPl"
            :data="taskDataPl"
            :rowKey="(record) => record.id"
            :scroll="{ y: 300 }"
          >
            <span slot="action" slot-scope="text, record">
              <a v-if="record.loginStatus === 0" @click="sendMsg(record)"
                >发送短信</a
              >
            </span>
          </s-table>
        </div>
        <div class="pl-total" >
          <span class="total-ze"
            >付款总额：<span class="total-zee">{{
              (Math.floor(tableInfo.payAmount * 100) / 100).toFixed(2)
            }}</span
            ><span class="total-zeee">元</span></span
          >
          
          
          <span class="total-ze"
            >总计人数：<span class="total-zeee"
              >{{ tableInfo.countNum }}人</span
            ></span
          >
        </div>
        <div class="tip_info" style="color:#5C5C5C;display:flex;align-items:center;">
            <img src="../../assets/tips.png" alt="" style="width:14px;height:14px;font-size:14px;color:#333">
             <span style="padding-left:10px;">未注册人员不计入付款总额</span>
        </div>
        <div class="common-opt">
          <a-button @click="handleCancelPl">取消</a-button>
          <a-button
            type="primary"
            style="margin-left: 10px"
            :disabled="!allowSubmit"
            :loading="confirmLoadingPl"
            @click="handleOkPl"
            >确认</a-button
          >
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { ACCESS_TOKEN } from "@/store/mutation-types";
import STable from "@/components/table/";
import {toDecimal2} from '@/utils/util'
import {
  raskDetail,
  raskCancel,
  raskEnd,
  getWorkList,
  raskAssign,
  refuseAssign,
  cancelAssign,
  getRaskTypeName,
  taskWorkerPayItem,
  queryImportWorkerList,
  importWorkerStatistics,
  importWorkerStatisticsChannel,
  channelConfirmPayList,
  getOrderList,
  submitPeriodPay,
  fulFilTask,
  sendInvitationSms,
} from '@/api/api'
export default {
  components: {
    STable,
  },
  data() {
    return {
      columnsYfkjsd: [
        {
          title: '创建时间',
          dataIndex: 'createTime',
          width: '200px'
        },
        {
          title: '用工周期',
          dataIndex: 'cycle',
          width: '200px',
          scopedSlots: { customRender: 'cycle' },
          width: '250px'
        },
        {
          title: '付款人数',
          dataIndex: 'payNum',
          customRender: text => text === null?'--':text
        },
        {
          title: '付款金额',
          dataIndex: 'payAmount',
          customRender: text => text === null?'--':text.toFixed(2)
        },
        {
          title: '付款状态',
          dataIndex: 'payStatus',
          customRender: text => text === '10' ? '提交成功' : text === '20' ? '复核成功' : text === '30' ? '付款成功' : text === '50' ? '任务结果处理中' : text === '60' ? '取消付款' : '待提交',
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        }
      ],
     
      visiblePl:false,//发起付款模态框状态
      url: window._CONFIG['domianURL'] + '/slash-youth/channel/task/importPayList',
      headers: {'X-Access-Token': Vue.ls.get(ACCESS_TOKEN)},
       importData: {
        taskId: ''
      },
      uploadInfo: [],
      columnsPl: [
        {
          title: '姓名',
          dataIndex: 'userName',
          width: '100px'
        },
        {
          title: '电话',
          dataIndex: 'phoneNo',
          width: '150px'
        },
        {
          title: '付款金额',
          dataIndex: 'payAmount',
          customRender: text => text === null?'--':(Math.floor(text * 100) / 100).toFixed(2),
          width: '100px'
        },
        
        {
          title: '注册状态',
          dataIndex: 'loginStatus',
          customRender: text => text === 1?'已注册':'未注册',
          width: '100px'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '100px',
          scopedSlots: { customRender: 'action' },
        },
      ],
      dataPl:[],
      tableInfo: {
        taskAmount: 0.00,
        advanceAmount:0.00,
        balanceAmount:0.00,
        feeAmount:0.00,
        payAmount:0.00,
        countNum:0.00,
      },
      taskId:'',//任务id
      taskDetailInfo:{},//任务详情
      taskTypeName:'',//任务类别名称
    //   batchId:'1384338262335205400',
      batchId:'666',
      allowSubmit:false,
      confirmLoadingPl:false,
      paramsOk:{

      },
     
    };
  },
  created () {
    console.log('route',this.$route.params.id);  
    this.taskId = this.$route.params.id;
    this.importData.taskId = this.taskId;
    this.getTaskDetail(); 
  },
  mounted () {
     
  },
  methods: {
      goSettlementDetail(){
          this.$router.push('/settlementDetail')
      },
      // 关闭任务
    closeTask() {
      console.log(66)
      let that = this
      this.$confirm({
        title: '确定关闭任务？',
        onOk() {
          return new Promise((resolve, reject) => {
            fulFilTask(that.taskId).then((res) => {
              if (res.success) {
                that.$message.success(res.message);
                that.toTaskManage();
                resolve();
              } else {
                that.$message.error(res.message);
                reject();
              }
            });
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {
          // console.log('Cancel');
        },
      });
    },
   //任务管理
      toTaskManage(){
          this.$router.push('/taskCenter')
          window.localStorage.setItem('submenu','taskCenter')
      },
    // 获取任务详情
    getTaskDetail () {
      raskDetail({id:this.taskId}).then(res => {
        // console.log('任务详情',res)
        if (res.success) {
          this.taskDetailInfo = res.result;
          console.log(this.taskDetailInfo);
          this.taskDetailInfo.budgetAmount= toDecimal2(this.taskDetailInfo.budgetAmount)
          
          this.getTaskTypeName(this.taskDetailInfo.taskType)
        }
      })
    },
    // 获取任务类别名称
    getTaskTypeName (id) {
      getRaskTypeName(id).then(res => {
        if (res.success) {
          this.taskTypeName = res.result.industryName
        }
      })
    },
    // 获取预付款列表getYfkList
    dataYfkjsd (parameter) {
      return getOrderList(Object.assign({taskId:this.taskId,},parameter)).then(res => {
        // this.disabledBtn1 = res.result.length===0?true:false
        // if (res.success) {
        //   this.totalFkxx = '付款信息(' + res.result.totalCount + ')'
        // }
        return res.result
      })
    },
    // 查询批量上传工作者列表
    taskDataPl(parameter) {
      return queryImportWorkerList(Object.assign(parameter,{batchId:this.batchId})).then(res => {
        this.dataPl = res.result.data
        this.allowSubmit = res.result.data.length===0?false:true
        return res.result
      })
    },
    // 汇总批量上传表格人数、金额
    importWorkerStatistics () {
      importWorkerStatisticsChannel(this.batchId).then(res => {
        if (res.success) {
          this.tableInfo = res.result
        }
      })
    },
    // 上传
    handleChange(info) {
        console.log('上传',info);
      if (info.fileList.length>1) {
        info.fileList.shift()
      }
      if (info.file.status==='removed'&&info.fileList.length===0) {
        this.batchId = '666'
        this.$refs.raskTable.refresh(true)
        this.tableInfo = {
          taskAmount: 0.00,
          advanceAmount:0.00,
          balanceAmount:0.00,
          feeAmount:0.00,
          payAmount:0.00,
          countNum:0.00,
        }
      }
      // console.log(info,info.file, info.fileList);
      this.uploadInfo = info.fileList
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status==='done') {
        if (info.file.response.success) {
          this.batchId = info.file.response.result.batchId
          this.$message.success(info.file.response.message);
          this.$refs.raskTable.refresh(true)
          this.importWorkerStatistics()
        } else {
          this.$message.error(info.file.response.message);
        }
      }
    },
    handleCancelPl () {
      this.visiblePl = false
      this.batchId = '666'
      this.$refs.raskTable.refresh(true)
      this.tableInfo = {
        taskAmount: 0.00,
        advanceAmount:0.00,
        balanceAmount:0.00,
        feeAmount:0.00,
        payAmount:0.00,
        countNum:0.00,
      }
      this.uploadInfo = []
    },
    handleOkPl () {
        if(!this.paramsOk.payPeriodStartTime){
            this.$message.info('请选择用工周期')
            return
        }
        if(!this.paramsOk.payPeriodEndTime){
            this.$message.info('请选择用工周期')
            return
        }
      let params = {
        taskId:this.taskId,
        batchId: this.batchId,
        payPeriodStartTime:this.paramsOk.payPeriodStartTime,
        payPeriodEndTime:this.paramsOk.payPeriodEndTime,
      }
      this.confirmLoadingPl = true
      channelConfirmPayList(params).then(res=>{
        this.confirmLoadingPl = false
        if (res.success) {
          this.$message.success(res.message)
          this.handleCancelPl()
          this.$refs.yfkTable.refresh(true)
          
        } else {
          this.$message.error(res.message)
        }
      })
    },
      // 任务周期
    rwTime (date, dateString) {
      this.paramsOk.payPeriodStartTime = dateString[0]
      this.paramsOk.payPeriodEndTime = dateString[1]
    },
    //付款详情
    payDetail(record){
      window.localStorage.setItem('submenu','payDetail')
      window.sessionStorage.setItem('outSideTaskId',this.taskId)
      this.$router.push('/payDetail/' +record.id)
    },
    // 提交结算
    submitPay(record) {
      let that = this
      this.$confirm({
        title: '确定提交结算？',
        onOk() {
            return new Promise((resolve, reject) => {
            submitPeriodPay({payId:record.id,taskId:that.taskId}).then((res) => {
              if (res.success) {
                that.$message.success(res.message);
                that.$refs.yfkTable.refresh(true)
                window.localStorage.setItem('submenu','settlementDetail')
                window.sessionStorage.setItem('taskInfo',JSON.stringify(res.result))
                window.location.reload(true)
                // that.$router.push('/settlementDetail')
                resolve();
              } else {
                that.$message.error(res.message);
                reject();
              }
            });
            }).catch(() => console.log('Oops errors!'));
          
        },
        onCancel() {
          // console.log('Cancel');
        },
      });
    },
    // 关闭任务
    closeRask(record) {
      console.log(record)
      let that = this
      this.$confirm({
        title: '确定关闭任务？',
        onOk() {
          return new Promise((resolve, reject) => {
            fulFilTask(that.raskId).then((res) => {
              if (res.success) {
                that.$message.success(res.message);
                that.ret();
                resolve();
              } else {
                that.$message.error(res.message);
                reject();
              }
            });
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {
          // console.log('Cancel');
        },
      });
    },
    // 发送短信
    sendMsg (record) {
      sendInvitationSms(record.phoneNo).then(res => {
        if (res.success) {
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
  },
};
</script>

<style lang="less">
.task_detail{
    padding: 24px;
}
.pl_time{
    display: flex;
    justify-content: space-between;
}
.pl-table{
  border: 1px solid #DADADA;
  border-radius: 6px;
  margin: 15px 0 0 0;
}
.pl-total{
  margin: 10px 0 10px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .total-ze{
    color: #33383E;
    font-size: 16px;
    margin: 15px 15px 0 0;
    .total-zee{
      color: #FF4D4F;
      font-size: 24px;
    }
    .total-zeee{
      color: #FF4D4F;
      font-size: 16px;
    }
  }
}
.xzmb{
  position: absolute;
  top: 5px;
  left: 100px;
}
.go_pay {
  margin: 24px 0;
}
.header {
  color: #000000;
  font-size: 14px;
  margin-bottom: 20px;
  .ret {
    color: rgba(0, 0, 0, 0.65);
    cursor: pointer;
  }
}
.header-one {
  padding: 27px 24px;
  border: 1px solid #f0f0f0;
  margin-bottom: 16px;
  // border-left: 0;
  // border-right: 0;
  // border-bottom: 0;
}
.rask-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .rt-left {
    display: flex;
    align-items: flex-end;
    .rt-left-title {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.85);
      margin-right: 16px;
    }
    .rt-left-time {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
    }
  }
}
.rask-content {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  .rc-mgb {
    margin-top: 24px;
    white-space: nowrap;
    &:last-child {
      width: 100%;
      text-align: justify;
      white-space: normal;
    }
    span {
      color: #1890ff;
    }
  }
}
.search {
  width: 100%;
  padding: 16px 0;
  // display: flex;
  align-items: center;
}
.model {
  padding: 0;
  list-style: none;
  li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    .span {
      width: 80px;
    }
    .mgt-5 {
      margin-top: 5px;
    }
    .tag {
      width: 360px;
      display: flex;
      flex-wrap: wrap;
      span {
        margin-bottom: 10px;
      }
    }
  }
}
.fkxx-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin: 10px 0 25px 0;
}
.fkxx-card {
  width: 500px;
  // height: 182px;
  padding: 20px 24px;
  border: 1px solid #f0f0f0;
  .fkxxc-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: bold;
  }
  .fkxxc-nn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .fkxxc-nn-left {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: bold;
    }
    .fkxxc-nn-right {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      display: flex;
      align-items: center;
      &::before {
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #f5222d;
        border-radius: 50%;
        content: "";
        margin-right: 5px;
      }
    }
  }
  .fkxx-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    margin-top: 30px;
    color: rgba(0, 0, 0, 0.85);
    span {
      width: 160px;
    }
  }
}
.table-point {
  display: flex;
  align-items: center;
  // justify-content: center;
  span {
    width: 4px;
    height: 4px;
    background: #1890ff;
    border-radius: 50%;
    margin-right: 4px;
  }
}
.shrw {
  list-style: none;
  padding: 0;
  li {
    margin: 0 0 20px 0;
  }
}
.common-img {
  width: 26px;
  height: 30px;
  margin: 0 10px 0 0;
}
.showExpand {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  li {
    margin: 10px 10px 0 0;
  }
}
.bmry {
  display: flex;
  justify-content: space-between;
}
.fsyq-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .fsyq-title {
    font-size: 14px;
    color: rgba(32, 32, 32, 0.65);
    margin: 0 0 14px 0;
  }
  .user-info {
    margin: 24px 0;
    .img {
      width: 100px;
      height: 100px;
      border-radius: 3px;
      margin-bottom: 8px;
      text-align: center;
      line-height: 100px;
      font-size: 40px;
      background: #dddddd;
      border-radius: 50%;
    }
    div {
      text-align: center;
      color: rgba(32, 32, 32, 0.65);
      font-size: 14px;
    }
  }
}
.common-content {
  .cc-title {
    padding: 18px 0;
    border-bottom: 1px solid #f0f0f0;
    font-size: 18px;
    color: #000000;
  }
  .cc-desc {
    font-size: 14px;
    color: rgba(32, 32, 32, 0.65);
    margin: 24px 0 0 0;
  }
  .cc-content {
    display: flex;
    align-items: center;
    align-items: center;
    font-size: 16px;
    color: #333333;
    margin: 24px 0 0 0;
  }
  .cc-footer {
    margin: 24px 0;
    padding: 20px 80px;
    width: 640px;
    // height: 95px;
    background: #fafafa;
    border-radius: 3px 0px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    .ccf-left,
    .ccf-right {
      color: #333333;
      font-size: 16px;
      span {
        font-size: 24px;
        color: #ff4d4f;
      }
      div {
        &:last-child {
          margin: 10px 0 0 0;
          font-size: 14px;
          color: rgba(32, 32, 32, 0.65);
        }
      }
    }
    .ccf-right {
      margin-left: 100px;
    }
  }
}
.common-opt {
  text-align: center;
  margin: 20px 0 20px 0;
}
.no-invite {
  margin: 17px 0 77px 0;
  text-align: center;
  font-size: 14px;
  color: rgba(32, 32, 32, 0.65);
}
.pop-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 16px 0;
  border-bottom: 1px solid #e1e4e8;
  .img {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    border-radius: 50%;
  }
  .person {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 10px;
    span {
      &:last-child {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.65);
        margin-left: 10px;
      }
    }
  }
  .construction {
    width: 230px;
    text-align: justify;
    font-size: 14px;
  }
}
.pic-select {
  padding: 0;
  margin: 0;
  list-style: none;
  li {
    margin: 10px 0;
  }
}
</style>
<style scoped>
/* For demo */
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 500px;
  line-height: 160px;
  /* background: #364d79; */
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 35px;
  height: 35px;
  font-size: 35px;
  color: #fff;
  background-color: rgba(31, 45, 61, 1);
  border-radius: 50%;
  opacity: 0.3;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
.ant-carousel >>> .slick-dots {
  display: none !important;
}
</style>